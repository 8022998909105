import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar'; // Asegúrate de importar el Sidebar

const Container = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  width: 80%;
  margin-left: 20%;
  padding: 20px;
  box-sizing: border-box;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  gap: 10px;
  align-items: center;
  text-align: left;
  margin-bottom: 10px;
`;

const AgregarData = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [agencias, setAgencias] = useState([]);
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedOption === 'Nave Agencia') {
      fetchAgencias();
    }
  }, [selectedOption]);

  useEffect(() => {
    validateForm();
  }, [formData, selectedOption]);

  const fetchAgencias = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/agencias');
    const data = await response.json();
    setAgencias(data);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setFormData({});
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let valid = false;
    switch (selectedOption) {
      case 'Agencia':
        valid = formData.nombre;
        break;
      case 'Nave Agencia':
        valid = formData.id_agencia && formData.nombre_nave;
        break;
      case 'Nave Practicos':
        valid = formData.nombre_nave;
        break;
      case 'Practico':
        valid = formData.nombre && formData.apellido;
        break;
      case 'Área de Maniobra':
        valid = formData.nombre_lugar;
        break;
      default:
        valid = false;
    }
    setIsFormValid(valid);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let url = '';

    switch (selectedOption) {
      case 'Agencia':
        url = 'https://api-practicos.dev.tabsa.cl/api/agencias';
        break;
      case 'Nave Agencia':
        url = 'https://api-practicos.dev.tabsa.cl/api/naves-agencia';
        break;
      case 'Nave Practicos':
        url = 'https://api-practicos.dev.tabsa.cl/api/naves';
        break;
      case 'Practico':
        url = 'https://api-practicos.dev.tabsa.cl/api/practicos';
        break;
      case 'Área de Maniobra':
        url = 'https://api-practicos.dev.tabsa.cl/api/lugares';
        break;
      default:
        return;
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    const data = await response.json();
    if (data.message) {
      alert(data.message);
    } else {
      alert('No se ha podido crear el registro');
    }
  };

  return (
    <>
      <br/>
      <br/>
      <br/>
      <center>
        <Container>
          <Sidebar />
          <MainContent>
            <div style={{ marginTop: '-0px' }} className='container'>
              <h2 style={{ color: 'black' }}>Crear Registro</h2>
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <label>Tipo de Registro:</label>
                  <select name="tipoRegistro" style={{width: "300px"}} onChange={handleOptionChange}>
                    <option hidden selected value="">Seleccionar</option>
                    <option value="Agencia">Agencia</option>
                    <option value="Nave Agencia">Nave Agencia</option>
                    <option value="Nave Practicos">Nave Practicos</option>
                    <option value="Practico">Practico</option>
                    <option value="Área de Maniobra">Área de Maniobra</option>
                  </select>
                </FormRow>

                {selectedOption === 'Agencia' && (
                  <FormRow>
                    <label>Nombre Agencia:</label>
                    <input type="text" name="nombre" onChange={handleInputChange} />
                  </FormRow>
                )}

                {selectedOption === 'Nave Agencia' && (
                  <>
                    <FormRow>
                      <label>Agencia:</label>
                      <select name="id_agencia" onChange={handleInputChange}>
                        <option hidden selected value="">Seleccionar</option>
                        {agencias.map(agencia => (
                          <option key={agencia.id_agencia} value={agencia.id_agencia}>
                            {agencia.nombre}
                          </option>
                        ))}
                      </select>
                    </FormRow>
                    <FormRow>
                      <label>Nombre Nave:</label>
                      <input type="text" name="nombre_nave" onChange={handleInputChange} />
                    </FormRow>
                  </>
                )}

                {selectedOption === 'Nave Practicos' && (
                  <FormRow>
                    <label>Nombre Nave:</label>
                    <input type="text" name="nombre_nave" onChange={handleInputChange} />
                  </FormRow>
                )}

                {selectedOption === 'Practico' && (
                  <>
                    <FormRow>
                      <label>Nombre:</label>
                      <input type="text" name="nombre" onChange={handleInputChange} />
                    </FormRow>
                    <FormRow>
                      <label>Apellido:</label>
                      <input type="text" name="apellido" onChange={handleInputChange} />
                    </FormRow>
                  </>
                )}

                {selectedOption === 'Área de Maniobra' && (
                  <FormRow>
                    <label>Nombre Lugar:</label>
                    <input type="text" name="nombre_lugar" onChange={handleInputChange} />
                  </FormRow>
                )}

                <input className='btn2' type="submit" value="Crear" disabled={!isFormValid} style={{ backgroundColor: isFormValid ? '' : 'gray' }} />
              </form>
            </div>
          </MainContent>
        </Container>
      </center>
    </>
  );
};

export default AgregarData;
