import { useParams } from 'react-router-dom';
import '../App.css';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { getUser, removeUserSession } from '../Utils/Common';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const Verify = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!getUser()) {
      navigate("/");
    }
  }, [navigate]);

  return null;
}

const cookies = new Cookies();

const Contenedor = styled.div`
  width: 20%;
  position: fixed;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #DFD7D5;
`;

const Menu = styled.ul`
  list-style-type: none;
`;

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    cookies.remove('user', { path: "/" });
    removeUserSession();
    navigate('/');
  }

  return (
    <Contenedor>
      <Menu>
        <li style={{ textAlign: 'left', borderBottom: "2px solid black", marginTop: "5px" }}>
          <Link to="/dashboard">INICIO</Link>
        </li>
        <li style={{ textAlign: 'left', borderBottom: "2px solid black", marginTop: "5px" }}>
          <Link to="/solicitudes-maniobras">SOLICITUDES DE MANIOBRAS</Link>
        </li>
        <li style={{ textAlign: 'left', borderBottom: "2px solid black", marginTop: "5px" }}>
          <Link to="/reportes">REPORTES DE MANIOBRAS</Link>
        </li>
        <li style={{ textAlign: 'left', borderBottom: "2px solid black", marginTop: "5px" }}>
          <Link to="/crear-solicitud">CREAR SOLICITUD</Link>
        </li>
        <li style={{ textAlign: 'left', borderBottom: "2px solid black", marginTop: "5px" }}>
          <Link to="/agregar-data">MANTENEDOR DE REGISTROS</Link>
        </li>
        <li style={{ textAlign: 'left', position: 'absolute', bottom: 130 }}>
          <a href='' onClick={handleLogout}>CERRAR SESIÓN</a>
        </li>
      </Menu>
    </Contenedor>
  );
}


export default Sidebar;