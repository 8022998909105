import React from 'react';
import Modal from 'react-modal';
import '../App.css';
import styled from 'styled-components';
import Sidebar from '../Sidebar/Sidebar';

const Container = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  width: 80%;
  margin-left: 20%;
  padding: 20px;
  box-sizing: border-box;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  gap: 10px;
  align-responses: center;
  text-align: left;
  margin-bottom: 10px;
`;

class Reportes extends React.Component {
  state = {
    showModal: false,
    details: null,
    showForm: false,
    data: [], 
  }

  componentDidMount() {
    fetch('https://api-practicos.dev.tabsa.cl/api/maniobras/all/filtro', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ estado: 7 })
    })
    .then(response => response.json())
    .then(data => this.setState({ data }))
    .catch(error => console.error(error));
  }

  openModal = (details, showForm = false) => {
    this.setState({ showModal: true, details, showForm });
  }

  closeModal = () => {
    this.setState({ showModal: false, details: null, showForm: false });
  }

  render() {
    const { showModal, details, showForm } = this.state;

    return (
      <>
      <br/>
      <br/>
      <br/>
      <center>
        <Container>
          <Sidebar/>
          <MainContent>
            {/* <div style={{marginTop: '-0px'}} className='container'> */}
              <h2 style={{color: 'black'}}>Reportes</h2>
              <br/> <br/> <br/> <br/>
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Agencia</th>
                    <th>Fecha Maniobra</th>
                    <th>Realizada por</th>
                    <th>Ver Reporte</th>
                  </tr>
                </thead>
                <tbody style={{textAlign: 'center'}}>
                  {this.state.data.map((response, index) => (
                    <tr key={index}>
                      <td>{response.id_maniobra}</td>
                      <td>{response.naves_agencia.agencia.nombre}</td>
                      <td>{response.timestamp_maniobra}</td>
                      <td>{response.usuario.nombre}</td>
                      <td> <button onClick={() => this.openModal({id: response.id_maniobra, agencia: response.naves_agencia.agencia.nombre, fecha: response.timestamp_maniobra, realizadaPor: response.usuario.nombre})}>Ver</button> </td>
                    </tr>
                  ))}
                </tbody>
                </table>

              <Modal
                isOpen={showModal}
                onRequestClose={this.closeModal}
                shouldCloseOnOverlayClick={false}
                style={{
                  content: {
                    width: '900px',
                    height: '890px',
                    margin: 'auto',
                    position: 'relative',
                  },
                }}
              >
                <button className="btn2 btn-danger" onClick={this.closeModal} style={{ position: 'absolute',top: '10px',right: '10px',}}>
                    X
                </button>
              {showForm ? (
                <div>
                  <h2 style={{color: 'black', textAlign: 'center'}}>ID {details.id}</h2>
                  <FormRow>
                  <label>Fecha:</label>
                  <input type="date" name="fecha" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Hora Inicio:</label>
                  <input type="time" name="hora" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Hora Término:</label>
                  <input type="time" name="horaTermino" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Nave:</label>
                  <input type="text" name="nave" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Tipo de Maniobra:</label>
                  <select style={{width: "300px"}} name="tipoManiobra" onChange={this.handleInputChange}>
                    <option value="Embarque">Embarque</option>
                    <option value="Desembarque">Desembarque</option>
                  </select>
                </FormRow>
                <FormRow>
                  <label>TRG:</label>
                  <input type="text" name="trg" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Bandera:</label>
                  <input type="text" name="banderaConveniencia" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Próximo Puerto:</label>
                  <input type="text" name="proximoPuerto" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>ETA:</label>
                  <FormRow>
                    <input style={{width: "130px"}} type="date" name="fechaEta" onChange={this.handleInputChange} />
                    <input style={{width: "130px", marginLeft: '-36px'}} type="time" name="horaEta" onChange={this.handleInputChange} />
                  </FormRow>
                </FormRow>
                <FormRow>
                  <label>Agencia:</label>
                  <input type="text" name="agencia" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Práctico 1:</label>
                  <input type="text" name="nombrePractico" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Práctico 2 (opcional):</label>
                  <input type="text" name="nombrePractico2" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Área de Maniobra:</label>
                  <input type="text" name="areaManiobra" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Condiciones de Tiempo:</label>
                  <select style={{width: "300px"}} name="condicionesTiempo" onChange={this.handleInputChange}>
                    <option value="Bueno">Bueno</option>
                    <option value="Regular">Regular</option>
                    <option value="Malo">Malo</option>
                  </select>
                </FormRow>
                <FormRow>
                  <label>Velocidad Viento:</label>
                  <input type="text" name="velocidadViento" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Dirección:</label>
                  <input type="text" name="direccion" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <label>Velocidad Corriente Marina:</label>
                  <input type="text" name="velocidadCorrienteMarina" onChange={this.handleInputChange} />
                </FormRow>
                <FormRow>
                  <FormRow>
                    <FormRow>
                      <label>Flujo</label>
                    </FormRow>
                    <FormRow>
                        <input type="radio" name="flujoReflujo" value="flujo" onChange={this.handleInputChange} />
                    </FormRow>
                  </FormRow>
                  <FormRow>
                    <FormRow>
                      <FormRow>
                        <label>Reflujo</label>
                      </FormRow>
                      <FormRow>
                        <input type="radio" name="flujoReflujo" value="reflujo" onChange={this.handleInputChange} />
                      </FormRow>
                    </FormRow>
                  </FormRow>
                </FormRow>
                </div>
              ) : (
                details && (
                  <div>
                    <h2 style={{color: 'black', textAlign: 'center'}}>ID {details.id}</h2>
                    <p>Agencia: {details.agencia}</p>
                    <p>Fecha Maniobra: {details.fecha}</p>
                    <p>Realizada por: {details.realizadaPor}</p>
                  </div>
                )
              )}
              {/* <div style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}>
                  <button className='btn2'>Aceptar</button>
              </div> */}
            </Modal>
          </MainContent>
        </Container>
      </center>
      </>
    );
  }
}

export default Reportes;