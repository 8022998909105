import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import axios from 'axios';

const FormRow = styled.div`
display: grid;
grid-template-columns: 1.2fr 2fr;
gap: 10px;
align-items: center;
text-align: left;
margin-bottom: 10px;
`;

const customStyles = {
content: {
  maxWidth: '540px',
  margin: 'auto',
  height: '90%',
  position: 'relative',
},
};

const DateTimeContainer = styled.div`
display: flex;
gap: 10px;
`;

const CustomModal = ({ isOpen, onRequestClose, openModalWithColor, maniobras }) => {
  const [currentManiobraIndex, setCurrentManiobraIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentManiobra, setCurrentManiobra] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedManiobra, setEditedManiobra] = useState(null);
  const [paises, setPaises] = useState([]);
  const [naves, setNaves] = useState([]);
  const [lugares, setLugares] = useState([]);
  const [condicionesClimaticas, setCondicionesClimaticas] = useState([]);
  const [practicos, setPracticos] = useState([]);
  const [agencias, setAgencias] = useState([]);



  useEffect(() => {
    fetchPaises();
    fetchNaves();
    fetchLugares();
    fetchCondicionesClimaticas();
    fetchPracticos();
    fetchAgencias();
  }, []);

  useEffect(() => {
    if (currentManiobra) {
      const [fecha, hora] = currentManiobra.timestamp_maniobra.split(' ');
      const [year, month, day] = fecha.split('-');
      const formattedFecha = `${day}-${month}-${year}`;
      setEditedManiobra({
        ...currentManiobra,
        fecha: formattedFecha,
        hora: hora.slice(0, 5), // Eliminar los segundos y milisegundos
      });
    }
  }, [currentManiobra]);

  useEffect(() => {
    setEditedManiobra(currentManiobra);
  }, [currentManiobra]);

  const fetchPaises = async () => {
    const response = await fetch('https://api-tool.dev.tabsa.cl/api/lista/paises');
    const data = await response.json();
    setPaises(data);
  };

  const fetchNaves = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/naves');
    const data = await response.json();
    setNaves(data);
  };

  const fetchLugares = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/lugares');
    const data = await response.json();
    setLugares(data);
  };

  const fetchPracticos = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/practicos');
    const data = await response.json();
    setPracticos(data);
  };

  const fetchAgencias = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/agencias');
    const data = await response.json();
    setAgencias(data);
  };

  const fetchCondicionesClimaticas = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/condiciones-climaticas');
    const data = await response.json();
    setCondicionesClimaticas(data);
  };

  const handleNext = () => {
    setCurrentManiobraIndex(prevIndex => prevIndex + 1);
  };

  const handlePrev = () => {
    setCurrentManiobraIndex(prevIndex => prevIndex - 1);
  };

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setEditedManiobra(prevState => {
      const newState = { ...prevState, [name]: value };
      if (name === 'fecha' || name === 'hora') {
        newState.timestamp_maniobra = `${newState.fecha} ${newState.hora}`;
      }
      if (name === 'timestamp_finalizacion_maniobra_fecha' || name === 'timestamp_finalizacion_maniobra_hora') {
        newState.timestamp_finalizacion_maniobra = `${newState.timestamp_finalizacion_maniobra_fecha} ${newState.timestamp_finalizacion_maniobra_hora}`;
      }
      return newState;
    });
  }, []);

  

  const handleSave = () => {
    const updatedManiobra = {
      ...editedManiobra,
      timestamp_finalizacion_maniobra: `${editedManiobra.timestamp_finalizacion_maniobra}`
    };
  
    axios.put(`https://api-practicos.dev.tabsa.cl/api/maniobras/${editedManiobra.id_maniobra}`, updatedManiobra)
      .then(response => {
        console.log(response);
        setCurrentManiobra(editedManiobra);
        setIsEditing(false);
      })
      .catch(error => {
        console.error('Error saving data: ', error);
      });
    setIsLoading(false);
  };  

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
    const selectedManiobra = maniobras[currentManiobraIndex];
    setCurrentManiobra(selectedManiobra);
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={!isEditing ? onRequestClose : undefined}
      style={customStyles}
      shouldCloseOnOverlayClick={false} // Evitar cerrar el modal clickeando fuera de él
    >
      {isLoading ? (
        <div>Cargando...</div>
      ) : (
        isOpen && maniobras.length > 0 && maniobras[currentManiobraIndex] && (
          <div>
            <button 
              className="btn2 btn-danger" 
              onClick={onRequestClose} 
              style={{ 
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
              hidden={isEditing}
            >
              X
            </button>
            {isLoading ? (
              <div>Cargando...</div>
            ) : (
              maniobras[currentManiobraIndex] && (
                isEditing ? (
                  <>
                    <h2 style={{ 
                      position: 'absolute',
                      top: '10px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      color: 'black',
                    }}>
                      ID #{editedManiobra ? editedManiobra.id_maniobra : ''}
                    </h2>
                    {editedManiobra && (
                      <form style={{alignItems: "center"}}>
                        <center>
                          <br/><br/><br/>
                          <FormRow>
                            <label>Fecha y Hora:</label>
                            <DateTimeContainer>
                              <input style={{maxWidth: "146.359px"}} type="date" name="fecha" value={editedManiobra.timestamp_maniobra?.split(' ')[0] || ''} onChange={handleInputChange} />
                              <input style={{maxWidth: "143.188px"}} type="time" name="hora" value={editedManiobra.timestamp_maniobra?.split(' ')[1] || ''} onChange={handleInputChange} />
                            </DateTimeContainer>
                          </FormRow>
                          <FormRow>
                            <label>Nave:</label>
                            <select style={{maxWidth: "300px"}} name="id_nave" value={editedManiobra.id_nave} onChange={handleInputChange}>
                              <option hidden value="">Seleccionar</option>
                              {naves.map(nave => (
                                <option key={nave.id_nave} value={nave.id_nave}>{nave.nombre_nave}</option>
                              ))}
                            </select>
                          </FormRow>
                          <FormRow>
                            <label>Tipo de Maniobra:</label>
                            <select style={{maxWidth: "300px"}} name="id_servicio" value={editedManiobra.id_servicio} onChange={handleInputChange}>
                              <option hidden value="">Seleccionar</option>
                              <option value="1">Embarque</option>
                              <option value="2">Desembarque</option>
                            </select>
                          </FormRow>
                          <FormRow>
                            <label>TRG:</label>
                            <input style={{maxWidth: "300px"}} type="number" name="trg" value={editedManiobra.trg} pattern="^\d+(\.\d{1,2})?$" onChange={handleInputChange} step="0.01" min="0"/>
                          </FormRow>
                          <FormRow>
                            <label>Bandera:</label>
                            <select style={{maxWidth: "300px"}} name="id_pais" value={editedManiobra.id_pais} onChange={handleInputChange}>
                              <option hidden value="">Seleccionar</option>
                              {paises.map(pais => (
                                <option key={pais.codigo} value={pais.codigo}>{pais.nombre}</option>
                              ))}
                            </select>
                          </FormRow>
                          <FormRow>
                            <label>Próximo Puerto:</label>
                            <input style={{maxWidth: "300px"}} type="text" name="descripcion" value={editedManiobra.descripcion} onChange={handleInputChange} />
                          </FormRow>
                          <FormRow>
                            <label>ETA:</label>
                            <DateTimeContainer>
                              <input style={{maxWidth: "146.359px"}} type="date" name="etaFecha" value={editedManiobra.etaFecha || ''} onChange={handleInputChange} />
                              <input style={{maxWidth: "143.188px"}} type="time" name="etaHora" value={editedManiobra.etaHora || ''} onChange={handleInputChange} />
                            </DateTimeContainer>
                          </FormRow>
                          {/* <FormRow>
                            <label>Agencia:</label>
                            <select style={{maxWidth: "300px"}} name="id_agencia" value={editedManiobra.naves_agencia.id_agencia} onChange={handleInputChange}>
                              <option hidden value="">Seleccionar</option>
                              {agencias.map(agencia => (
                                <option key={agencia.id_agencia} value={agencia.id_agencia}>{agencia.nombre}</option>
                              ))}
                            </select>
                          </FormRow> */}
                          <FormRow>
                            <label>Práctico 1:</label>
                            <select style={{maxWidth: "300px"}} name="id_practico_1" value={editedManiobra?.id_practico_1 || ''} onChange={handleInputChange}>
                              <option hidden value="">Seleccionar</option>
                              {practicos.map(practico => (
                                <option key={practico.id_practico} value={practico.id_practico}>
                                  {practico.nombre} {practico.apellido}
                                </option>
                              ))}
                            </select>
                          </FormRow>
                          <FormRow>
                            <label>Práctico 2 (Opcional):</label>
                            <select style={{maxWidth: "300px"}} name="id_practico_2" value={editedManiobra?.id_practico_2 || ''} onChange={handleInputChange}>
                              <option hidden value="">Seleccionar</option>
                              {practicos.map(practico => (
                                <option key={practico.id_practico} value={practico.id_practico}>
                                  {practico.nombre} {practico.apellido}
                                </option>
                              ))}
                            </select>
                          </FormRow>
                          <FormRow>
                            <label>Área de Maniobra:</label>
                            <select style={{maxWidth: "300px"}} name="id_lugar" value={editedManiobra?.id_lugar || ''} onChange={handleInputChange}>
                              <option hidden value="">Seleccionar</option>
                              {lugares.map(lugar => (
                                <option key={lugar.id_lugar} value={lugar.id_lugar}>{lugar.nombre_lugar}</option>
                              ))}
                            </select>
                          </FormRow>
                          <FormRow>
                            <label>Condiciones de Tiempo:</label>
                            <select style={{maxWidth: "300px"}} name="condicion_climatica" value={editedManiobra?.id_condicion_climatica || ''} onChange={handleInputChange}>
                              <option hidden value="">Seleccionar</option>
                              {condicionesClimaticas.map(condicion_climatica => (
                                <option key={condicion_climatica.id_condicion_climatica} value={condicion_climatica.id_condicion_climatica}>{condicion_climatica.descripcion}</option>
                              ))}
                            </select>
                          </FormRow>
                          <FormRow>
                            <label>Finalización de Maniobra:</label>
                            <DateTimeContainer>
                              <input style={{maxWidth: "146.359px"}} type="date" name="timestamp_finalizacion_maniobra_fecha" value={editedManiobra?.timestamp_finalizacion_maniobra?.split(' ')[0] || ''} onChange={handleInputChange} />
                              <input style={{maxWidth: "143.188px"}} type="time" name="timestamp_finalizacion_maniobra_hora" value={editedManiobra?.timestamp_finalizacion_maniobra?.split(' ')[1] || ''} onChange={handleInputChange} />
                            </DateTimeContainer>
                          </FormRow>
                          <FormRow>
                            <label>Marcar como reportada:</label>
                            <input 
                              type="checkbox" 
                              name="reportada" 
                              checked={editedManiobra.reportada || false} 
                              onChange={(e) => setEditedManiobra(prevState => ({
                                ...prevState,
                                reportada: e.target.checked
                              }))}
                            />
                          </FormRow>
                          <button 
                            className="btn1" 
                            onClick={handleSave} 
                            style={{ 
                              position: 'absolute',
                              bottom: '10px',
                              left: '30%',
                              transform: 'translateX(-50%)',
                            }}
                          >Guardar</button>
                          <button 
                            className="btn1" 
                            onClick={handleCancel} 
                            style={{ 
                              position: 'absolute',
                              bottom: '10px',
                              right: '30%',
                              transform: 'translateX(50%)',
                            }}
                          >Cancelar</button>
                        </center>
                      </form>
                    )}
                  </>
                ) : (
                  <>
                    <h2 style={{ 
                      position: 'absolute',
                      top: '10px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      color: 'black',
                    }}>
                      ID #{maniobras[currentManiobraIndex].id_maniobra}
                    </h2>
                    <div>
                      <br/><br/><br/>
                      Fecha: {maniobras[currentManiobraIndex].timestamp_maniobra}<br/>
                      Nave: {maniobras[currentManiobraIndex].naves_agencia.nombre_nave}<br/>
                      Tipo de Maniobra: {maniobras[currentManiobraIndex].servicio.nombre_servicio}<br/>
                      TRG: {maniobras[currentManiobraIndex].trg}<br/>
                      Bandera: {maniobras[currentManiobraIndex].iso3166.nombre}<br/>
                      Próximo Puerto: {maniobras[currentManiobraIndex].descripcion}<br/>
                      ETA: {maniobras[currentManiobraIndex].eta}<br/>
                      Agencia: {maniobras[currentManiobraIndex].naves_agencia.agencia.nombre}<br/>
                      Práctico 1: {maniobras[currentManiobraIndex].id_practico_1}<br/>
                      Práctico 2 (Opcional): {maniobras[currentManiobraIndex].id_practico_2}<br/>
                      Área de Maniobra: {maniobras[currentManiobraIndex].lugar.nombre_lugar}<br/>
                      Condiciones de Tiempo: {maniobras[currentManiobraIndex].condicion_climatica.descripcion}<br/>
                      Finalización de Maniobra: {maniobras[currentManiobraIndex].timestamp_finalizacion_maniobra}<br/>
                      <button 
                        className="btn1" 
                        onClick={handleEdit} 
                        hidden={maniobras[currentManiobraIndex].color == 2}
                        style={{ 
                          position: 'absolute',
                          bottom: '10px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                        }}
                      >Editar</button>
                    </div>
                  </>
                )
              )
            )}
          <div onClick={() => openModalWithColor(maniobras[currentManiobraIndex].color)}>
          </div>
        {!isEditing ? (
          <center>
            <br/>
            <button 
              className='btn2' 
              onClick={handlePrev} 
              disabled={currentManiobraIndex === 0}
              style={{ backgroundColor: currentManiobraIndex === 0 ? 'gray' : '' }}
            >
              Anterior
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button 
              className='btn2' 
              onClick={handleNext} 
              disabled={currentManiobraIndex === maniobras.length - 1}
              style={{ backgroundColor: currentManiobraIndex === maniobras.length - 1 ? 'gray' : '' }}
            >
              Siguiente
            </button>
          </center>
        ) : <></>}
        </div>
        )
      )}
    </Modal>
  );
};

export default CustomModal;
