import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Reportes from './Reportes/Reportes';
import Login from './Login/Login';
import PrivateRoute from './Utils/PrivateRoute';
import CrearSolicitud from './CrearSolicitud/CrearSolicitud';
import SolicitudManiobra from './SolicitudManiobra/SolicitudManiobra';
import AgregarData from './AgregarData/AgregarData'; // Importa el nuevo componente

function App() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <>
      <head>
        <link rel="stylesheet" type="text/css" href="https://api-tool.dev.tabsa.cl/storage/estilos.css"></link>
      </head>
      <header>
        <img src='https://recursos.tabsa.cl/storage/images/JcHwtxCU.png' className="logo"></img>
      </header>
      <br/>
      <BrowserRouter>
        <div>
          <div className="content">
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route element={<PrivateRoute />}>
                <Route path='/dashboard' element={<Dashboard />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route exact path="/reportes" element={<Reportes />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route exact path="/solicitudes-maniobras" element={<SolicitudManiobra />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route exact path="/crear-solicitud" element={<CrearSolicitud />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route exact path="/agregar-data" element={<AgregarData />} /> {/* Nueva ruta */}
              </Route>
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
