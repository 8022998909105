import React from 'react';
import CustomModal from './Modal';
import { useState, useEffect } from 'react';
import '../App.css';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { getUser, removeUserSession } from '../Utils/Common';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Calendar from 'react-calendar';
import styled from 'styled-components';
import Sidebar from '../Sidebar/Sidebar';
import Modal from 'react-modal';

const Verify= () => {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    if (!getUser()){
      navigate("/")   
    }
  },[])
}

const cookies = new Cookies();

const Container = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  width: 80%;
  margin-left: 20%;
  padding: 20px;
  box-sizing: border-box;
`;

const CalendarContainer = styled.div`
  width: 80%;
`;

class Dashboard extends React.Component {
  state = {
    date: new Date(),
    showModal: false,
    maniobras: [],
    currentManiobra: null,
    isEditing: false,
    editedManiobra: null,
    selectedColor: null,
    refreshCalendar: false,
    isLoading: false,
  }

componentDidMount() {
  axios.get('https://api-practicos.dev.tabsa.cl/api/maniobras')
    .then(response => {
      const maniobras = response.data;
      const maniobraDates = maniobras.map(maniobra => new Date(maniobra.timestamp_maniobra));
      this.setState({ maniobras, maniobraDates });
    })
    .catch(error => {
      console.error('Error fetching data: ', error);
    });
}

  openModal = () => {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false, isEditing: false, refreshCalendar: true });
  }

  onChange = date => this.setState({ date })

  onClickDay = (value, event) => {
    const maniobrasDelDiaYColor = this.state.maniobras.filter(maniobra =>
      new Date(maniobra.timestamp_maniobra).getDate() === value.getDate() &&
      new Date(maniobra.timestamp_maniobra).getMonth() === value.getMonth() &&
      new Date(maniobra.timestamp_maniobra).getFullYear() === value.getFullYear() &&
      maniobra.color === this.state.selectedColor 
    );
  
    if (maniobrasDelDiaYColor.length > 0) {
      const dateString = `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`;
  
      axios.post('https://api-practicos.dev.tabsa.cl/api/maniobras/modal', { fecha: dateString, color: this.state.selectedColor })
        .then(response => {
          this.setState({ currentManiobras: response.data, showModal: true });
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
        });
    }
  }

  onClickMonth = month => {
    this.setState({ date: month });
  }

  handleEdit = () => {
    this.setState({ isEditing: true });
  }

  handleInputChange = (event) => {
    this.setState({
      editedManiobra: {
        ...this.state.editedManiobra,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSave = () => {
    axios.post('https://api-practicos.dev.tabsa.cl/api/test', this.state.editedManiobra)
      .then(response => {
        console.log(response);
        this.setState({ isEditing: false, currentManiobra: this.state.editedManiobra });
      })
      .catch(error => {
        console.error('Error saving data: ', error);
      });
  }

  openModalWithColor = (color) => {
    this.setState({ isLoading: true });
    this.setState({ isLoading: true, selectedColor: color });

    const maniobrasDelColor = this.state.maniobras.filter(maniobra => maniobra.color === color);
    
    this.setState({ currentManiobras: maniobrasDelColor, showModal: true, selectedColor: color, currentManiobraIndex: 0 });
  
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }
  
  handleCancel = () => {
    this.setState({ isEditing: false });
  }

  handleCardClick = (date, color) => {
    const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  
    const data = {
      fecha: dateString,
      color: color,
    };
  
    axios.post('https://api-practicos.dev.tabsa.cl/api/maniobras/modal', data)
      .then(response => {
        this.setState({ currentManiobras: response.data, showModal: true });
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }
  
  

  fetchManiobraDetails = (id) => {
    this.setState({ isLoading: true });
    axios.get(`https://api-practicos.dev.tabsa.cl/api/maniobra/${id}`)
      .then(response => {
        const maniobraDetails = response.data;
        this.setState({ currentManiobra: maniobraDetails, isLoading: false });
        this.setState({ editedManiobra: maniobraDetails, isLoading: false });
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const currentMonth = monthNames[this.state.date.getMonth()];

    return (
      <>
        <br/>
        <br/>
        <br/>
        <center>
        <CustomModal 
          isOpen={this.state.showModal} 
          onRequestClose={this.closeModal} 
          openModalWithColor={this.openModalWithColor}
          maniobras={this.state.currentManiobras} 
        />

          <Sidebar />
          <Container>
            <Sidebar/>
            <MainContent>
            <div style={{ width: '115px', height: '45px', border: '2px solid black', borderRadius: "6px", backgroundColor: '#6F727C', color: "white", display: 'inline-block' }}>
            </div>
            <div style={{ width: '115px', height: '45px', border: '2px solid black', borderRadius: "6px", backgroundColor: '#D13333', color: "white", display: 'inline-block' }}>
            </div>
            <div style={{ width: '115px', height: '45px', border: '2px solid black', borderRadius: "6px", backgroundColor: '#3359D1', color: "white", display: 'inline-block' }}>
            </div>
            <br/>
            <div style={{ width: '115px', height: '75px', color: "white", display: 'inline-block' }}>
              <p style={{ textAlign: 'center'}}>Maniobra Sin Reporte</p>
            </div>
            <div style={{ width: '115px', height: '75px', color: "white", display: 'inline-block' }}>
              <p style={{ textAlign: 'center'}}>Maniobra Reportada</p>
            </div>
            <div style={{ width: '115px', height: '75px', color: "white", display: 'inline-block' }}>
              <p style={{ textAlign: 'center'}}>Maniobra Futura</p>
            </div>
            <br/><br/>
            <h1 style={{color: "black"}}>{currentMonth}</h1>
              <CalendarContainer>
              <Calendar
                key={this.state.refreshCalendar ? 'refreshed' : ''}
                onChange={this.onChange}
                value={this.state.date}
                onClickDay={this.onClickDay}
                tileContent={({ date, view }) => {
                  if (view === 'month') {
                    const maniobrasDelDia = this.state.maniobras.filter(maniobra =>
                      new Date(maniobra.timestamp_maniobra).getDate() === date.getDate() &&
                      new Date(maniobra.timestamp_maniobra).getMonth() === date.getMonth() &&
                      new Date(maniobra.timestamp_maniobra).getFullYear() === date.getFullYear()
                    );
                
                    if (maniobrasDelDia.length > 0) {
                      const eventCounts = maniobrasDelDia.reduce((acc, maniobra) => {
                        const key = maniobra.color;
                        if (!acc[key]) {
                          acc[key] = { count: 0, color: '' };
                        }
                        acc[key].count += 1;
                      
                        if (maniobra.color === 1) {
                          acc[key].color = '#6F727C';
                        } else if (maniobra.color === 2){
                          acc[key].color = '#D13333';
                        } else if (maniobra.color === 3) {
                          acc[key].color = '#3359D1';
                        }
                      
                        return acc;
                      }, {});
              
                      return (
                        <div className='calendarioPracticos' style={{ marginTop: "-5px", display: 'flex', justifyContent: 'center', textAlign: "center", fontWeight: "bolder" }}>
                          {Object.keys(eventCounts).map(key => {
                            let width;
                            switch (Object.keys(eventCounts).length) {
                              case 1:
                                width = '90%';
                                break;
                              case 2:
                                width = '45%';
                                break;
                              case 3:
                                width = '30%';
                                break;
                              default:
                                width = '30%';
                            }
                            return (
                              <div 
                                key={key} 
                                style={{ backgroundColor: eventCounts[key].color, height: "24px", borderRadius: "2px", border: "2px solid", borderColor: "black", width: width, margin: '0 2.5%' }}
                                onClick={() => this.handleCardClick(date, eventCounts[key].color)}  
                              >
                                <p style={{ fontSize: "12px", color: "white" }}>{eventCounts[key].count}</p>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                  }
                  return null;
                }}
                navigation={false}
                tileClassName={({ date, view }) =>
                  date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear() && view === 'month' ? 'react-calendar__tile--now' : null
                }
                formatShortWeekday={(locale, date) => ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'][date.getDay()]}
              />
            </CalendarContainer>
            <br/>
            <button className='btn1' onClick={() => this.onClickMonth(new Date(this.state.date.setMonth(this.state.date.getMonth() - 1)))}>Anterior</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button className='btn1' onClick={() => this.onClickMonth(new Date(this.state.date.setMonth(this.state.date.getMonth() + 1)))}>Siguiente</button>
          </MainContent>
        </Container>
        </center>
      </>
    );
  }
}

export default Dashboard;