import React from 'react';
import Modal from 'react-modal';
import '../App.css';
import styled from 'styled-components';
import Sidebar from '../Sidebar/Sidebar';

const Container = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  width: 80%;
  margin-left: 20%;
  padding: 20px;
  box-sizing: border-box;
`;

class SolicitudManiobra extends React.Component {
  state = {
    showModal: false,
    details: null,
    solicitudes: [],
    loading: false,
  }

  componentDidMount() {
    this.setState({ loading: true });
    fetch('https://api-practicos.dev.tabsa.cl/api/maniobras/all/filtro', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ estado: 1 })
    })
    .then(response => response.json())
    .then(data => {
      if (Array.isArray(data)) {
        this.setState({ solicitudes: data, loading: false });
      } else {
        console.error('Error: la respuesta no es un array');
      }
    });
  }

  openModal = (details) => {
    this.setState({ showModal: true, details });
  }

  closeModal = () => {
    this.setState({ showModal: false, details: null });
  }

  handleAction = (estado) => {
    const { details } = this.state;
    fetch(`https://api-practicos.dev.tabsa.cl/api/gestionar-solicitud`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: details.id_maniobra, estado })
    })
    .then(response => response.json())
    .then(data => {
      alert(data.mensaje);
      this.setState({ showModal: false, details: null });
      this.componentDidMount(); // Refresh the data
    });
  }

  render() {
    const { showModal, details, solicitudes, loading } = this.state;

    return (
      <>
      <br/>
      <br/>
      <br/>
      <center>
        <Container>
          <Sidebar/>
          <MainContent>
            <h2 style={{color: 'black'}}>Solicitudes de Maniobras</h2>
            <br/> <br/> <br/> <br/>
            {loading ? (
              <p>Cargando...</p>
            ) : (
              <table>
                  <thead style={{textAlign: 'center'}}>
                    <tr>
                      <th>ID</th>
                      <th>Solicitante</th>
                      <th>Fecha Solicitada</th>
                      <th>Servicio</th>
                      <th>Flag</th>
                      <th>Nave</th>
                      <th>TRG</th>
                      <th>Gestionar</th>
                    </tr>
                  </thead>
                  <tbody style={{textAlign: 'center'}}>
                  {solicitudes.map((solicitud, index) => (
                    <tr key={index}>
                      <td>{solicitud.id_maniobra}</td>
                      <td>{solicitud.usuario ? solicitud.usuario.username : 'Cargando...'}</td>
                      <td>{solicitud.timestamp_maniobra}</td>
                      <td>{solicitud.servicio.nombre_servicio}</td>
                      <td>{solicitud.iso3166.nombre}</td>
                      <td>{solicitud.nave.nombre_nave}</td>
                      <td>{solicitud.trg}</td>
                      <td> <button onClick={() => this.openModal(solicitud)}>Gestionar</button> </td>
                    </tr>
                  ))}
                  </tbody>
              </table>
            )}
            <Modal
                isOpen={showModal}
                onRequestClose={this.closeModal}
                shouldCloseOnOverlayClick={false} 
                style={{
                    content: {
                    width: '900px',
                    height: '890px',
                    margin: 'auto',
                    position: 'relative',
                    },
                }}
                >
                <button 
                    className="btn2 btn-danger" 
                    onClick={this.closeModal} 
                    style={{ 
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    }}
                >
                    X
                </button>
                {details && (
                  <div>
                    <h2 style={{color: 'black', textAlign: 'center'}}>ID {details.id_maniobra}</h2>
                    <p><b>Solicitante:</b> {details.usuario.username}</p>
                    <p><b>Fecha Solicitada:</b> {details.timestamp_maniobra}</p>
                    <p><b>Servicio:</b> {details.servicio.nombre_servicio}</p>
                    <p><b>Flag:</b> {details.iso3166.nombre}</p>
                    <p><b>Nave:</b> {details.nave.nombre_nave}</p>
                    <p><b>TRG:</b> {details.trg}</p>
                  </div>
                )}
                <div style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}>
                  <button className='btn2' style={{ marginRight: '10px' }} onClick={() => this.handleAction(2)}>Aceptar</button>
                  <button className='btn2 btn-danger' onClick={() => this.handleAction(3)}>Rechazar</button>
                </div>
            </Modal>
          </MainContent>
        </Container>
      </center>
      </>
    );
  }
}

export default SolicitudManiobra;