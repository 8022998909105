import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { getToken, getUser } from './Common';

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Outlet
      {...rest}
      render={(props) => getUser() ? <Component {...props} /> : <Navigate to={{ pathname: '/', state: { from: props.location } }} />}
    />
  )
}

export default PrivateRoute;

// import React from 'react';
// import { Navigate, Outlet } from 'react-router-dom';

// const PrivateRoute = ({ component: Component, ...rest }) => {

//     // If authorized, return an outlet that will render child elements
//     // If not, return element that will navigate to login page
//     return  <Outlet  {...rest}
//     render={(props) => getToken() ? <Component {...props} />  :  
//     <Navigate to={{ pathname: '/', state: { from: props.location } }} />
//   }